import { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

if (import.meta.env.VITE_SENTRY_ENVIRONMENT) {
  Sentry.init({
    dsn: 'https://e7790471924f46e5ada7e4afcc63cace@o4507652019322880.ingest.us.sentry.io/4507667850133504',
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    tracesSampleRate: 1.0,

    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.1,
  })
}

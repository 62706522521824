import React, { createContext, useContext, useRef } from 'react'
import NotificationSystem from 'react-notification-system'

import { colors, FlexRow, margins, Text3 } from 'css/css'

import errorSvg from 'img/shared/notification/error.svg'
import successSvg from 'img/shared/notification/success.svg'
import { getIsMobile } from 'js/util/util'

interface ContextProps {
  showNotif: (notif: NotificationSystem.Notification) => void
}

const NotifContext = createContext<ContextProps>({
  showNotif: () => undefined,
})

export const useNotifContext = () => useContext(NotifContext)

export const NotifProvider = ({ children }: { children: React.ReactNode }) => {
  const notifSystem = useRef<NotificationSystem>(null)

  const showNotif = (notif: NotificationSystem.Notification) => {
    if (!notifSystem.current) {
      return
    }

    const { message: originalMessage, level } = notif
    const notifLevel = level || 'success'
    const newNotif = { ...notif, message: '', level: notifLevel }

    switch (notifLevel) {
      case 'success': {
        notifSystem.current.addNotification({
          position: isMobile ? 'tl' : 'tc',
          dismissible: false,
          children: (
            <FlexRow style={{ alignItems: 'center' }}>
              <img style={{ marginRight: margins.size4 }} src={successSvg} alt="success" />
              <Text3 style={{ color: colors.whiteMain }}>{originalMessage}</Text3>
            </FlexRow>
          ),
          ...newNotif,
        })
        break
      }
      case 'error': {
        notifSystem.current.addNotification({
          position: isMobile ? 'tl' : 'tc',
          dismissible: false,
          children: (
            <FlexRow style={{ alignItems: 'center' }}>
              <img style={{ marginRight: margins.size4 }} src={errorSvg} alt="error" />
              <Text3 style={{ color: colors.whiteMain }}>{originalMessage}</Text3>
            </FlexRow>
          ),
          ...newNotif,
        })
        break
      }
      default: {
        notifSystem.current.addNotification({
          position: isMobile ? 'tl' : 'tc',
          dismissible: true,
          ...notif,
        })
      }
    }
  }

  const contextValue = { showNotif }
  const isMobile = getIsMobile()
  const styles = {
    Containers: {
      DefaultStyle: {
        width: '100%',
        maxWidth: '600px',
      },
    },
    NotificationItem: {
      DefaultStyle: {
        borderRadius: 10,
        display: 'inline-table',
        padding: margins.size4,
        marginTop: `calc(${margins.size2} + env(safe-area-inset-top))`,
      },

      success: {
        backgroundColor: colors.blueGrey,
        border: 'none',
        boxShadow: 'rgb(0 0 0 / 90%) 0px 0px 1px',
      },

      error: {
        backgroundColor: colors.blueGrey,
        border: 'none',
        boxShadow: 'rgb(0 0 0 / 90%) 0px 0px 1px',
      },
    },
  }

  return (
    <NotifContext.Provider value={contextValue}>
      <NotificationSystem ref={notifSystem} style={styles} />
      {children}
    </NotifContext.Provider>
  )
}

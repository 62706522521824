import { Suspense } from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { LoadingSpinner } from './shared-components'

const router = createBrowserRouter([
  {
    path: '/',
    lazy: () => import('./Wrapper'),
    children: [
      {
        index: true,
        lazy: () => import('./TradesRoute'),
      },
      {
        path: '/trade',
        lazy: () => import('./TradesRoute'),
      },

      {
        path: '*',
        lazy: () => import('./pages/not-found'),
      },
      {
        path: '/portfolio',
        lazy: () => import('./pages/portfolio'),
      },
    ],
  },
])

const App = () => {
  return (
    <Suspense
      fallback={
        <div>
          <LoadingSpinner />
        </div>
      }
    >
      <RouterProvider router={router} />
    </Suspense>
  )
}
export default App
